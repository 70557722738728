<template>
  <div>
    <section class="border-top border-bottom" style="border: none !important">
      <div class="masthead container">
        <swiper
          :centeredSlides="true"
          :autoplay="{
            delay: 7000,
            disableOnInteraction: false,
          }"
          :navigation="true"
          :loop="true"
        >
          <swiper-slide v-for="(banner, idx) in banners" :key="`${banner.path}${idx}`">
            <a :href="banner.redirectURL" target="_blank" class="d-block h-100">
              <img
                :src="$imageFilters(banner.imagePath)"
                :alt="banner.alternativeText"
                class="d-block w-100"
              />
            </a>
          </swiper-slide>
        </swiper>
      </div>
    </section>
    <!-- CSR -->
    <AppSection data-name="csr">
      <HomeArrowSwipers
        v-if="infos.length"
        elementName="csr"
        :breakpoints="{
          320: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          480: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          800: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
        }"
      >
        <swiper-slide v-for="info in infos" :key="info">
          <div
            class="card border-0 cursor-pointer"
            @click="$router.push(`/news/${info.type}/${info.articleUuid}`)"
          >
            <div class="row g-0">
              <div class="col-md-4">
                <img
                  :src="`${
                    (info.imagePath && $imageFilters(info.imagePath)) ||
                    require('@/assets/image/csr/csr-2.png')
                  }`"
                  class="img-fluid obj-fit-cover"
                  style="height: 120px"
                  alt="csr資訊"
                />
              </div>
              <div class="col-md-8">
                <div class="card-body py-0 row flex-column justify-content-between h-100">
                  <h6 class="card-title fsz-7-limit-2 text-black">{{ info.title }}</h6>
                  <p class="card-text text-gray-600 fs-9 fs-lg-8">
                    {{ info.categoryName }}
                  </p>
                  <p class="card-text fs-9 fs-lg-8">
                    <small class="text-secondary">更多</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </HomeArrowSwipers>
    </AppSection>

    <!-- 解決方案 -->
    <AppSection data-name="solusion" bgColor="bg-gray-100">
      <ul class="row mb-3">
        <li
          class="card border-0 col-12 col-md-6 col-lg-3 py-5"
          :class="(idx + 1) % 2 === 1 ? 'bg-gray-200' : 'bg-white'"
          v-for="(solution, idx) in solutions"
          :key="solution.uuid"
        >
          <div class="card-body">
            <h5 class="card-title fsz-5-limit-1 text-gray-700">{{ solution.title }}</h5>
            <p class="card-text fs-8 fs-lg-7 fw-light mb-4 limit-line-4">
              {{ solution.brief }}
            </p>
            <a
              class="card-text fs-8 fs-lg-7"
              href="#"
              @click.prevent="
                $router.push({
                  name: 'SolutionServiceDetails',
                  params: { id: solution.articleUuid },
                })
              "
            >
              <small class="text-secondary">更多</small>
            </a>
          </div>
        </li>
        <li class="card border-0 col-12 col-md-6 col-lg-3 py-5 order-1 order-lg-0">
          <div class="card-body d-flex flex-column justify-content-center align-items-center">
            <button
              class="btn btn-secondary text-white w-100 rounded-0 fs-5"
              @click="$router.push({ name: 'SolutionService' })"
            >
              查看所有解決方案
            </button>
          </div>
        </li>
      </ul>
    </AppSection>

    <!-- 產品 -->
    <AppSection data-name="product">
      <h3 class="d-md-none text-center mb-5 fw-light">產品</h3>
      <div class="row mb-3">
        <div class="col-12 col-md-10">
          <ul class="row g-0">
            <li class="col-4 col-md-2" v-for="product in products" :key="product">
              <a
                class="px-3"
                href="#"
                @click.prevent="$router.push(`/product?type=${product.nameEn?.toLowerCase()}`)"
              >
                <img :src="$imageFilters(product.imagePath)" :alt="product.nameEn" />
                <p class="fw-light text-center">{{ product.nameCh }}</p>
              </a>
            </li>
          </ul>
        </div>
        <div class="d-none d-md-block col-2 align-self-center">
          <div class="d-flex flex-column justify-content-center align-items-center">
            <button
              class="btn btn-secondary text-white w-100 rounded-0 fs-5"
              @click="$router.push({ name: 'product' })"
            >
              查看更多產品
            </button>
          </div>
        </div>
      </div>
    </AppSection>

    <!-- 合作夥伴 -->
    <AppSection data-name="company" bgColor="bg-gray-100" v-if="partners.length">
      <h3 class="text-center mb-5 mb-lg-6 fw-light">我們的合作夥伴</h3>
      <HomeArrowSwipers
        elementName="company"
        :breakpoints="{
          320: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          480: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          800: {
            slidesPerView: 5,
            spaceBetween: 20,
          },
        }"
      >
        <swiper-slide v-for="partner in partners" :key="partner.id">
          <div>
            <a :href="partner.url" target="_blank">
              <img
                :src="$imageFilters(partner.imagePath)"
                alt="partner"
                style="height: 140px"
                class="obj-fit-contain"
              />
            </a>
          </div>
        </swiper-slide>
      </HomeArrowSwipers>
    </AppSection>
  </div>
</template>

<script>
import { apiQueryUserBanner } from "@/api/banner";
import { apiQueryUserBrand } from "@/api/brand";
import { apiQueryProduct } from "@/api/product";
import { apiQueryUserArticle } from "@/api/article";
import HomeArrowSwipers from "../../components/HomeArrowSwipers.vue";
import { NEWS_INFO, SOLUTION } from "../../constants/article";

export default {
  name: "Home",
  components: {
    HomeArrowSwipers,
  },
  data() {
    return {
      defaultImages: [
        {
          alternativeText: "banner3",
          imagePath: "/images/424ea76f-8864-4a9a-b56f-72eeaed707ac.jpeg",
          redirectURL: "",
        },
      ],
      banners: [],
      partners: [],
      infos: [],
      solutions: [],
      products: [],
    };
  },
  methods: {
    async queryBanners(page = 1) {
      this.$vLoading(true);
      try {
        const res = await apiQueryUserBanner({ page: page - 1 });
        const { code, data, message } = res.data;
        if (code === 200) {
          this.banners = data.length === 0 ? this.defaultImages : data;
        } else {
          this.$vHttpsNotice({ statusCode: code, title: message });
        }
      } catch (error) {
        this.$vErrorNotice();
      } finally {
        this.$vLoading(false);
      }
    },
    async queryBrands() {
      try {
        const res = await apiQueryUserBrand();
        const { code, data, message } = res.data;
        if (code === 200) {
          this.partners = data;
        } else {
          this.$vHttpsNotice({ statusCode: code, title: message });
        }
      } catch (error) {
        // regardless
      }
    },
    async queryProduct(type, size = 6) {
      this.$vLoading(true);
      try {
        const res = await apiQueryProduct({ type, size });
        const { code, data, message } = res.data;
        if (code === 200) {
          this.products = data;
        } else {
          this.$vHttpsNotice({ statusCode: code, title: message });
        }
      } catch (error) {
        this.$vErrorNotice();
      } finally {
        this.$vLoading(false);
      }
    },
    async queryUserArticle(type, size = 10) {
      this.$vLoading(true);
      try {
        const res = await apiQueryUserArticle({ type, size });
        const { code, data, message } = res.data;
        if (code === 200) {
          const { content } = data;
          return content;
        } else {
          this.$vHttpsNotice({ statusCode: code, title: message });
        }
      } catch (error) {
        this.$vErrorNotice();
      } finally {
        this.$vLoading(false);
      }
    },
  },
  async created() {
    this.queryBanners();
    this.queryBrands();
    this.queryProduct();
    this.infos = await this.queryUserArticle(NEWS_INFO.key, 9);
    this.solutions = await this.queryUserArticle(SOLUTION.key, 3);
  },
};
</script>
